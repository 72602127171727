<template>
	<div>
		<div class="display-none" style="width: 100%;">
			<img style="width: 100%;" :src="agent_info.report_banner.url"/>
		</div>
		<div class="_w-max _pc-pad-x-280 report-index mb-pad-0">
			<div class="main-box mb-main-box">
				<div class="main-box-left mb-main-box-nav">
					<div class="main-box-left-li mb-li" @click="switchType(1)" :class="type === 1 ? 'main-box-left-li-select mb-li-select' : ''" style="cursor: pointer;">
						{{ $t('单个报备') }}
					</div>
					<div class="main-box-left-li mb-li" @click="switchType(2)" :class="type === 2 ? 'main-box-left-li-select mb-li-select' : ''" style="cursor: pointer;">
						{{ $t('批量报备') }}
					</div>
					<div class="main-box-left-li mb-li" @click="switchType(3)" :class="type === 3 ? 'main-box-left-li-select mb-li-select' : ''" style="cursor: pointer;">
						{{ $t('报备记录') }}
					</div>
				</div>
				<!-- <router-view class="main-box-right"></router-view> -->
				<!-- 单个报备 -->
				<reportOnePack class="main-box-right" v-if="type === 1"></reportOnePack>
				<!-- 批量报备 -->
				<reportBatchPack class="main-box-right" v-if="type === 2"></reportBatchPack>
				<!-- 包裹列表 -->
				<reportList class="main-box-right" v-if="type === 3"></reportList>
			</div>
		</div>
	</div>
</template>

<script>
	import reportBatchPack from './components/reportBatchPack.vue';
	import reportOnePack from './components/reportOnePack.vue';
	import reportList from './components/reportList.vue';
	import { bannerList } from '@/api/jiyun';
	export default {
		components: {
			reportBatchPack,
			reportOnePack,
			reportList
		},
		name: 'report',
		data() {
			return {
				// metaInfo
				title: this.$t('page_title.report'),
				nav:'',
				scrollTop: 0,
				type: 1,
				agent_info: {}
			}
		},
		watch: {
			$route: {
				handler(newval, oldval) {
					this.nav = newval.path;
				}
			},
		},
		metaInfo() {
			return {
				title: this.title, // set a title
			};
		},
		created() {
			this.nav = this.$route.path;
			window.addEventListener('scroll',this.handleScroll,true);
			this.bannerList();
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			handleScroll(){
				let top = Math.floor(document.body.scrollTop||document.documentElement.scrollTop);
				this.scrollTop = top;
			},
			switchType(type){
				this.type = type;
			},
		}
	}
</script>

<style scoped>
	@media screen and (min-width: 768px){
		.report-index {
				padding-top: 70px;
				padding-bottom: 40px;
				display: flex;
				align-items: center;
			}
			.main-box {
				width: 100%;
				display: flex;
			}
			.main-box-left {
				/* min-height: 735px;  */
				width: 305px;
				padding-top: 30px;
				padding-bottom: 30px;
				border-right: 1px solid #D1D1D1;
				margin-right: 70px;
			}
			.main-box .main-box-left .main-box-left-li {
				width: 100%;
				padding-right: 24px;
				font-size: 24px;
				color: #333333;
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				position: relative;
				line-height: 1.7;
				margin-bottom: 60px;
			}
			.main-box .main-box-left .main-box-left-li-select {
				color: #4284DC;
			}
			
			.main-box .main-box-left .main-box-left-li-select::after {
				content: "";
				width: 5px;
				height: 27px;
				background: #4284DC;
				position: absolute;
				right: -2px;
			}
			.main-box .main-box-right {
				flex: 1;
			}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem;
			padding-bottom: 0.9375rem;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
		.mb-main-box {
			width: 100%;
			background: #fff;
			padding-bottom: 1.875rem;
		}
		.mb-main-box .mb-main-box-nav {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			border-bottom: 1px solid #e2e2e2;
		}
		.mb-main-box .mb-main-box-nav .mb-li {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0.5rem 0rem;
			font-size: 1.25rem;
		}
		.mb-main-box .mb-main-box-nav .mb-li-select {
			color: #4284DC;
			font-weight: bold;
			position: relative;
		}
		.mb-main-box .mb-main-box-nav .mb-li-select::after {
			content: '';
			position: absolute;
			bottom: -1px;
			width: 16%;
			height: 0.125rem;
			background-color: #4284DC;
		}
	}
</style>